<template>
  <!--compatibility 男女合盘-->
  <div class="compatibility">
    <article class="compatibility-wrap">
      <!--cover 产品主视觉-->
      <section class="cover">
        <div class="cover-txt">
          <h1>
            <span>恋爱<em>速配</em>合盘</span
            ><span>看看我和TA的速配程度？</span>
          </h1>
          <p>
            正挣扎着该不该表白吗？<br />桃花太多该选择谁？<br />忐忑不安的暧昧期，该行动还是放手？<br />桃桃喜来指点迷津！
          </p>
        </div>
      </section>
      <!--/cover 产品主视觉-->
      <!--infomation 输入资讯-->
      <section class="infomation">
        <div class="con-wrap infomation-wrap">
          <h2 class="blk-tit">
            <span class="tit-main">请输入合盘两方的资料</span>
          </h2>
          <form class="forms-basic infomation-form">
            <div class="form-wrap">
              <!--个人资料-->
              <div class="infomation-infoself">
                <div class="form__item">
                  <label class="form__label" for="input_name">您的姓名</label>
                  <div class="form__container">
                    <input
                      required
                      id="input_name"
                      class="form__input"
                      placeholder="输入您的姓名"
                      type="text"
                      v-model="name1"
                    />
                  </div>
                </div>
                &emsp;可直接输入阳历,如：1990-06-15
                <div class="form__item">
                  <label class="form__label" for="input_date">阳历生日</label>
                  <div class="form__container">
                    <input
                      required
                      id="input_date"
                      v-model="birthday1"
                      class="form__input"
                      placeholder="1990-06-15"
                      type="text"
                    />
                  </div>
                  <div
                    class="date-input-wrapper"
                    onclick="document.getElementById('input_date').click();"
                  ></div>
                </div>
                <div class="form__item form-group">
                  <label class="form__label" for="input_time">出生时辰</label>
                  <div class="form__container">
                    <select
                      id="input_time exampleFormControlSelect1"
                      class="form-control"
                      v-model="birthHour"
                    >
                      <option value="0">0:00 - 0:59 (早子)</option>
                      <option
                        v-for="(item, index) in shiChenList"
                        :key="index"
                        :value="index"
                        >{{ index }}:00 - {{ parseInt(index) + 1 }}:59 ({{
                          item
                        }})</option
                      >
                      <option value="23">23:00 - 23:59 (晚子)</option>
                    </select>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">您的性别</label>
                  <label style="margin-right: 24px;" for="male"
                    ><input
                      name="input_gender"
                      id="male"
                      type="radio"
                      value="1"
                      v-model="gender1"
                    />
                    男</label
                  >
                  <label for="female"
                    ><input
                      name="input_gender"
                      id="female"
                      type="radio"
                      value="2"
                      v-model="gender1"
                    />
                    女</label
                  >
                </div>
              </div>
              <!--对方资料-->
              <div class="infomation-infofriend">
                <div class="form__item">
                  <label class="form__label" for="input_target_name"
                    >对⽅姓名</label
                  >
                  <div class="form__container">
                    <input
                      required
                      id="input_target_name"
                      class="form__input"
                      placeholder="输入对⽅姓名"
                      type="text"
                      v-model="name2"
                    />
                  </div>
                </div>
                &emsp;可直接输入阳历,如：1990-06-15
                <div class="form__item">
                  <label class="form__label" for="input_target_date"
                    >阳历生日</label
                  >
                  <div class="form__container">
                    <input
                      required
                      id="input_target_date"
                      v-model="birthday2"
                      class="form__input"
                      placeholder="1990-06-15"
                      type="text"
                    />
                  </div>
                  <div
                    class="date-input-wrapper"
                    onclick="document.getElementById('input_target_date').click();"
                  ></div>
                </div>
                <div class="form__item">
                  <label class="form__label">对⽅性别</label>
                  <label style="margin-right: 24px;" for="target_male"
                    ><input
                      name="input_target_gender"
                      id="target_male"
                      type="radio"
                      value="1"
                      v-model="gender2"
                    />
                    男</label
                  >
                  <label for="target_female"
                    ><input
                      name="input_target_gender"
                      id="target_female"
                      type="radio"
                      value="2"
                      v-model="gender2"
                    />
                    女</label
                  >
                </div>
              </div>
            </div>
            <!--动作区块-->
            <div class="infomation-action">
              <p class="infomation-note">为求测试结果准确，请仔细核对资讯</p>
              <div v-on:click="submitToPreview()" class="btn-action">
                立即测算
              </div>
              <p class="infomation-note" @click="linkToHistory()">
                查看歷史訂單 &gt;&gt;
              </p>
            </div>
          </form>
        </div>
      </section>
      <!-- /infomation 输入资讯-->
      <!--intro 产品介绍-->
      <section class="intro">
        <div class="con-wrap intro-wrap">
          <h2 class="blk-tit">
            <span class="tit-main">本测算提供以下分析</span>
          </h2>
          <ol class="list-intro">
            <li>
              <figure class="intro-icon _icon1"></figure>
              <p class="intro-txt">你的感情观如何？</p>
            </li>
            <li>
              <figure class="intro-icon _icon2"></figure>
              <p class="intro-txt">你在TA眼中是怎样的人？</p>
            </li>
            <li>
              <figure class="intro-icon _icon3"></figure>
              <p class="intro-txt">TA是怎样的人？<br />个性特点、喜好跟雷区</p>
            </li>
          </ol>
          <section class="intro-items">
            <section class="intro-know">
              <h3><span>跟TA相处，你该知道的</span></h3>
              <ul>
                <li>TA喜欢的事情—<br class="lg-hide sm-show" />积极加分的事</li>
                <li>TA的地雷区—<br class="lg-hide sm-show" />应避免踩的雷</li>
                <li>
                  你们交往的优势
                </li>
                <li>
                  你们交往的劣势
                </li>
              </ul>
            </section>
            <section class="intro-next">
              <h3><span>下一步到底会如何？</span></h3>
              <ul>
                <li>TA喜欢你吗？</li>
                <li>你们在一起的机率多高？</li>
                <li>你们交往相处的最佳年份</li>
                <li>你们容易摩擦不顺的年份</li>
              </ul>
            </section>
          </section>
        </div>
      </section>
      <!--/intro 产品介绍-->
      <!--comment 评论-->
      <section class="comment">
        <div class="con-wrap">
          <!--list-comment 评论列表-->
          <VueSlickCarousel v-bind="settings" class="list-comment">
            <div class="item">
              <div class="item-photo">
                <figure class="fig1"></figure>
                <p class="photo-name">Doris</p>
              </div>
              <div class="item-txt">
                <h4>长长久久的秘密</h4>
                <p>
                  过去在感情关系上，常常出现无意间踩到对方的痛点，导致争执摩擦，最后不欢而散。能先知道心仪对象在意的痛点，让自己有机会在心态或行动上调整，真的很重要！
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item-photo">
                <figure class="fig2"></figure>
                <p class="photo-name">跑跑</p>
              </div>
              <div class="item-txt">
                <h4>放下与释怀</h4>
                <p>
                  抱持着半信半疑的态度来算，输入前任的资料，没想到说中了当初不合分手的原因。看了分析结果，好像也能稍微释怀了，并不是我不够好，也许就只是两个人原本个性不合，感觉自己终于可以有个新开始了。
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item-photo">
                <figure class="fig3"></figure>
                <p class="photo-name">不減十斤不改名</p>
              </div>
              <div class="item-txt">
                <h4>新恋情的小心机</h4>
                <p>
                  哈～耍点小心机来算了一下暧昧对象，照着建议投其所好，超有效啊哈哈～～！还满推荐陷入苦恼，或是犹豫不知道怎么行动的人来玩玩看，会很有帮助喔！
                </p>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </section>
      <!--/comment 评论-->
      <!--teacher 老师介绍-->
      <section class="teacher">
        <div class="con-wrap">
          <h2 class="blk-tit">
            <span class="tit-main"><em>吴明光</em>老师介绍</span>
          </h2>
          <section class="teacher-con">
            <figure class="teacher-photo"></figure>
            <div class="teacher-txt">
              <p>
                吴明光老师，13年命理占卜经验，出身术数世家。
              </p>

              <p>
                师承台湾王派风水、紫微斗数占验派、萧湘派面相学、擅阴阳宅风水学。
              </p>

              <p>
                实战派命理师，经过十多年帮无数人服务解惑，已成为两岸三地各大企业主争相邀请的命理大师，于2015年创立「桃桃喜」。
              </p>

              <p>
                擅长运用命理工具来协助每一位朋友跟伙伴从分析现况开始，经由了解运势方向，到作出最有利的决定，成功帮助无数客户朋友度过爱情，工作以及人生的难关。
              </p>
            </div>
          </section>
        </div>
      </section>
      <!--/teacher 老师介绍-->
      <!--about 关于紫微斗数-->
      <section class="about">
        <div class="con-wrap">
          <h2 class="blk-tit">
            <span class="tit-main"><em>关于</em>紫微斗数</span
            ><span class="tit-sub">紫微斗数 帝王学</span>
          </h2>
          <ol class="list-about">
            <li>
              <h3>紫微斗数的由来</h3>
              <div>
                <p>
                  紫微斗数相传为五代方士陈抟（872年—989年）观星所创。在古代被尊称为「帝王学」，并在皇宫中设有「钦天监」对其进行研究，能接触到紫微斗数的人只限于皇族中人或是有皇帝钦点的官员。
                  后来随着国民党退守台湾，有关于紫微斗数的书籍也随之流入台湾。致使紫微斗数在台湾的传承度非常完整。大师高手辈出！
                </p>
              </div>
            </li>
            <li>
              <h3>紫微斗数测算的准度</h3>
              <div>
                <p>
                  紫微斗数经过千年的演进,星曜安宫的计算方法相当完备,
                  能经由个人命盘精准判断人生大运, 小限, 流年,
                  流月以及作用在12宫位上的事件, 包括工作, 感情, 财运, 婚恋,
                  身体, 家族等等全方面, 钜细靡遗的解析。
                </p>
              </div>
            </li>
            <li>
              <h3 @click="cleanLocalStorage()">本测算可提供的协助</h3>
              <div>
                <p>
                  本测算针对人生最常遭遇的几大困扰, 如感情困扰,工作运势,
                  流年运势上的起伏,依照你个人命盘进行解析,
                  然后给您最有帮助的答案, 细节可以参考各大测验的内页说明。
                </p>
              </div>
            </li>
          </ol>
        </div>
      </section>
      <!--/about 关于紫微斗数-->
    </article>
  </div>
  <!--/compatibility 男女合盘-->
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import moment from 'moment-timezone'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { SHI_CHEN } from '@/constants/shi-chen-list'
import { ROUTE_CONST } from '@/constants/router-constant'

export default {
  name: 'Home',
  data() {
    return {
      name1: null,
      birthday1: null,
      birthHour: '0',
      gender1: '2',
      name2: null,
      birthday2: null,
      gender2: '1',
      settings: {
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        autoplay: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '30px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 340,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '15px',
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  components: { VueSlickCarousel },
  mounted: function() {
    try {
      TtxTrack.track('he-pan', 'FORTUNE_INDEX')
    } catch (e) {
      // console.log(e)
    }
    var birthday1 = new datePicker()
    birthday1.init({
      trigger: '#input_date',
      type: 'date',
      onSubmit: function() {},
      onClose: function() {}
    })
    var birthday2 = new datePicker()
    birthday2.init({
      trigger: '#input_target_date',
      type: 'date',
      onSubmit: function() {},
      onClose: function() {}
    })
  },
  computed: {
    shiChenList: function() {
      let result = { ...SHI_CHEN }
      delete result[0]
      delete result[23]
      return result
    },
    formValid1: function() {
      if (this.name1 && !isChinese(this.name1)) {
        return false
      } else if (this.birthday1 && this.name1) {
        if (this.name1.length <= 4 && this.name1.length >= 2) {
          return true
        }
      }
      return false
    },
    formValid2: function() {
      if (this.name2 && !isChinese(this.name2)) {
        return false
      } else if (this.birthday2 && this.name2) {
        if (this.name2.length <= 4 && this.name2.length >= 2) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    cleanLocalStorage: function() {
      window.localStorage.clear()
      location.reload()
    },
    submitToPreview: function() {
      if (this.formValid1 && this.formValid2) {
        var birthday1 = moment(this.birthday1)
        var birthday2 = moment(this.birthday2)
        var payload = {}

        payload.name = this.name1
        payload.gender = parseInt(this.gender1)
        payload.birthdayYear = birthday1.year()
        payload.birthdayMonth = birthday1.month() + 1
        payload.birthdayDate = birthday1.date()
        payload.birthdayHour = parseInt(this.birthHour)

        payload.targetName = this.name2
        payload.targetGender = this.gender2
        payload.targetBirthdayYear = birthday2.year()
        payload.targetBirthdayMonth = birthday2.month() + 1
        payload.targetBirthdayDate = birthday2.date()

        payload.quizYear = moment.tz('Asia/Shanghai').year()

        this.$store.commit('fortune/setRequestData', payload)

        this.$router.push(ROUTE_CONST.PREVIEW.path)
      } else {
        if (
          !this.birthday1 ||
          this.name1 == '' ||
          this.name1 === null ||
          !this.birthday2 ||
          this.name2 == '' ||
          this.name2 === null
        ) {
          alert('请输入生日与姓名')
        } else if (
          this.name1.length < 2 ||
          this.name1.length > 4 ||
          this.name2.length < 2 ||
          this.name2.length > 4
        ) {
          alert('注意姓名长度为2-4的中文字符')
        } else {
          alert('注意姓名长度为2-4的中文字符')
        }
      }
    },
    linkToHistory: function() {
      this.$router.push({ name: 'History' })
    }
  }
}
var isChinese = function(s) {
  var ret = true
  for (var i = 0; i < s.length; i++) {
    ret = ret && s.charCodeAt(i) >= 10000 //判断文本字符的unicode值
  }
  return ret
}
</script>

<style src="@/assets/css/compatibility.css"></style>
<style></style>
